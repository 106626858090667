import Image from "next/image";

import Styled from "./index.styles";

export default function ShipdaLogo() {
  return (
    <Styled.logo>
      <Image
        src={"/assets/images/layout/shipda_logo_white.webp"}
        alt="shipda-logo"
        width={59}
        height={24}
      />
    </Styled.logo>
  );
}
