import styled, { css } from "styled-components";

import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const footerContainer = styled.footer<{ paddingBottom?: number }>`
  width: 100%;
  min-width: 1280px;
  background-color: ${COLOR.grayScale_900};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & * {
    word-break: keep-all;
  }

  ${mobile(css`
    min-width: 100%;

    /* 모바일의 경우 비로그인시 하단에 회원가입 버튼이 추가되므로 고려하여 padding-bottom 적용이 되어있다 */
    padding: 48px 0 60px 0;
  `)}

  > .app-build-info {
    > .built-at {
      position: absolute;
      top: 10px;
      right: 10px;
      /* 스크롤할때만 보이도록 배경과 동일한 색상 */
      color: ${COLOR.grayScale_900};
      cursor: default;
      font-size: 12px;
    }

    > .hidden {
      display: none;
    }
  }

  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${paddingBottom}px !important;
    `}
`;

const footerMainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid ${COLOR.grayScale_800};

  ${mobile(css`
    border: 0;
  `)}
`;

const footerMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 64px 0 40px 0;

  ${mobile(css`
    width: 100%;
    padding: 0;
    flex-direction: column;
  `)}
`;

const leftContainer = styled.div`
  width: 319px;
  margin-right: 81px;

  ${mobile(css`
    width: 100%;
    margin: 0;
    padding: 0 16px;
  `)}

  .desktop-aeo-footer {
    margin-top: 25px;
  }
`;

const contactInfo = styled.ul`
  margin-top: 16px;

  ${mobile(css`
    margin-top: 24px;
  `)}

  li {
    color: ${COLOR.grayScale_400};
    margin-top: 8px;

    ${mobile(css`
      margin-top: 4px;
    `)}

    span.category {
      ${setFontStyle("Body3")}
    }

    strong {
      ${setFontStyle("SubHead3")}
    }

    .text-button {
      margin-left: 4px;
    }
  }
`;

const footerBottomContainer = styled.div`
  display: flex;
  width: 1240px;
  padding: 32px 0 47px 0;
  color: ${COLOR.grayScale_700};
  ${ShipdaCurrentLanguage.currentLanguage === "ko"
    ? setFontStyle("Body4")
    : setFontStyle("Body4", "Point_ENG")}

  ${mobile(css`
    width: 100%;
    padding: 0;
    flex-direction: column;
  `)}

  a,
  a:visited {
    color: ${COLOR.grayScale_700};
    ${ShipdaCurrentLanguage.currentLanguage === "ko"
      ? setFontStyle("Body4")
      : setFontStyle("Body4", "Point_ENG")}
  }
`;

const snsList = styled.ul`
  display: flex;

  ${mobile(css`
    border-top: 1px solid ${COLOR.grayScale_800};
    padding-top: 16px;
  `)}

  li {
    &::after {
      content: "･";
      margin: 0 6px;
    }

    &:last-child::after {
      display: none;
    }
  }
`;

const footerBottomRightContainer = styled.div`
  width: ${ShipdaCurrentLanguage.currentLanguage === "ko" ? "880px" : "630px"};

  ${mobile(css`
    width: 100%;
    flex-direction: column;
    padding: 0 16px;
    margin-top: 16px;
  `)}

  .company-info {
    margin-top: 16px;
    ${ShipdaCurrentLanguage.currentLanguage === "ko"
      ? setFontStyle("Body5")
      : setFontStyle("Caption", "Point_ENG")}

    ${mobile(css`
      ${ShipdaCurrentLanguage.currentLanguage === "ko"
        ? setFontStyle("Caption")
        : setFontStyle("Caption", "Point_ENG")}
    `)}
  }

  .mobile-aeo-footer {
    margin-top: 16px;
  }
`;

export default {
  footerContainer,
  footerMainWrapper,
  footerMainContainer,
  leftContainer,
  contactInfo,
  footerBottomContainer,
  snsList,
  footerBottomRightContainer,
};
