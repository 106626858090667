import { useRouter } from "next/router";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import AlertBanner from "@sellernote/_sds-v2/src/components/AlertBanner";

import Styled from "./index.styles";

const QUOTE_URL = "/forwarding/quote";

export default function ExportationBanner() {
  const { isMobile } = useCheckIsMobile();

  const { push: routerPush, pathname } = useRouter();

  const isQuotePage = pathname.includes(QUOTE_URL);

  return (
    <Styled.exportationBanner>
      <AlertBanner
        className="custom-alert-banner"
        label={
          isMobile
            ? "수출 서비스가 오픈되었어요!"
            : "수출 스케줄 및 견적조회 기능이 추가되었습니다! 지금 바로 확인해보세요."
        }
        /** 통합견적의뢰 페이지가 아닌 경우에만 수출 견적의뢰 버튼 표시 */
        {...(!isQuotePage && {
          textButtonProps: {
            theme: "white",
            fontSize: 14,
            label: "수출 견적의뢰하기",
            handleClick: () =>
              routerPush("/forwarding/quote?queryStringCase=polSelect&polId=1"),
          },
        })}
      />
    </Styled.exportationBanner>
  );
}
