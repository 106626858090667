import React, { MutableRefObject } from "react";
import Image from "next/image";
import { Trans, useTranslation } from "next-i18next";

import { InvitedTeamInfo } from "@sellernote/_shared/src/types/common/team";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";
import FourChevronRightIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronRightIcon";

import Styled from "./index.styles";

export default function Contents({
  currentModalInfo,
  totalInvitedTeamCount,
  currentInvitedTeamOrder,
  handleModalClose,
}: {
  currentModalInfo: InvitedTeamInfo;
  totalInvitedTeamCount: MutableRefObject<number>;
  currentInvitedTeamOrder: MutableRefObject<number>;
  handleModalClose: () => void;
}) {
  const { t } = useTranslation(["team"]);

  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.container>
      <p className="invited-team-guide">
        <Trans
          i18nKey="team:팀_초대_초대_안내"
          values={{ teamName: currentModalInfo.teamName }}
        />
      </p>

      <span className="invited-team-info">
        ({t("team:팀_초대_팀마스터")}: {currentModalInfo.masterName},{` `}
        {currentModalInfo.masterEmail}, {currentModalInfo.masterPhone})
      </span>

      <Image
        src={"/assets/images/layout/invited-team-modal-illust.webp"}
        alt="invited-team-modal-illust"
        width={isMobile ? 169.7 : 290}
        height={isMobile ? 140 : 240}
      />

      <span className="order-and-total-count">
        {t("team:팀_초대_초대장")} (
        <strong>{currentInvitedTeamOrder.current}</strong>/
        {totalInvitedTeamCount.current})
      </span>

      {isMobile && (
        <TextButton
          label={t("team:팀_초대_나중에하기_버튼")}
          theme="primary"
          iconInfo={{
            Icon: FourChevronRightIcon,
            position: "right",
          }}
          fontSize={14}
          handleClick={handleModalClose}
        />
      )}
    </Styled.container>
  );
}
