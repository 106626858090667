import React, { Dispatch, SetStateAction } from "react";
import Router from "next/router";
import { useTranslation } from "next-i18next";

import {
  APP_BUILD_INFO,
  IS_UNDER_PRODUCTION,
  SERVICE_INTRODUCTION_INQUIRY_URL,
} from "@sellernote/_shared/src/constants";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import LinkButton from "@sellernote/_sds-v2/src/components/button/Button/LinkButton";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";
import Divide from "@sellernote/_sds-v2/src/components/Divide";
import SubmenuAnnouncementIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuAnnouncementIcon";
import SubmenuBookIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuBookIcon";
import SubmenuCalendarIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuCalendarIcon";
import SubmenuCodeIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuCodeIcon";
import SubmenuFlightIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuFlightIcon";
import SubmenuGiveMoneyIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuGiveMoneyIcon";
import SubmenuInternationalIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuInternationalIcon";
import SubmenuPlayerIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuPlayerIcon";
import SubmenuSurpriseIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuSurpriseIcon";
import SubmenuTruckIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuTruckIcon";
import SubmenuVesselIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuVesselIcon";

import SubmenuItem from "../components/SubmenuItem/index";

import TempLanguageButton from "../../../TempLanguageButton";
import Logo from "../../Logo";
import User from "../../User";
import MenuItem from "./MenuItem";
import Styled from "./index.styles";

export default function DesktopHeader({
  loggedIn,
  setIsVisibleLoginModal,
  isTransparentBackground,
}: {
  loggedIn: boolean;
  setIsVisibleLoginModal: Dispatch<SetStateAction<boolean>>;
  isTransparentBackground?: boolean;
}) {
  const { t } = useTranslation(["common-new"]);

  return (
    <Styled.desktopHeader>
      {/* 임시 언어 전환 버튼 */}
      {!IS_UNDER_PRODUCTION && <TempLanguageButton />}

      {!IS_UNDER_PRODUCTION && APP_BUILD_INFO && (
        <div className="build-info">
          {toFormattedDate(APP_BUILD_INFO.builtAt, "YYYY.MM.DD HH:mm:ss Z")}
        </div>
      )}

      <Styled.leftContainer>
        <Logo type="header" />

        <nav>
          <Styled.menuContainer>
            <MenuItem label="회사소개" path="/company" />

            <MenuItem label="서비스">
              <Styled.submenuPanel width="869px">
                <ul>
                  <Styled.submenuTitle>Forwarding</Styled.submenuTitle>

                  <SubmenuItem
                    label="해상운송"
                    desc="수출입 해상운임 견적의뢰"
                    path="/forwarding/ocean"
                    badges={["FCL", "LCL"]}
                    Icon={<SubmenuVesselIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label="항공운송"
                    desc="수출입 항공운임 견적의뢰"
                    path="/forwarding/air"
                    badges={["AIR"]}
                    Icon={<SubmenuFlightIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label="스케줄 조회"
                    desc="해운/항공사별 스케줄 조회"
                    path="/forwarding/schedule"
                    badges={["ALL"]}
                    Icon={<SubmenuCalendarIcon width={28} height={28} />}
                  />
                </ul>
                <Divide
                  lineStyle="line"
                  thickness={1}
                  type="vertical"
                  height="230px"
                />
                <ul>
                  <Styled.submenuTitle>Fulfillment</Styled.submenuTitle>

                  <SubmenuItem
                    label="국내 출고"
                    desc="스마트스토어, 쿠팡, 카페24 등 국내출고"
                    path="/fulfillment/domestic"
                    badges={["B2B", "B2C"]}
                    Icon={<SubmenuTruckIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label="해외 출고"
                    desc="Amazon, Shopify, Rakuten 등 해외출고"
                    path="/fulfillment/overseas"
                    badges={["B2B", "B2C"]}
                    Icon={<SubmenuInternationalIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label="이벤트 출고"
                    desc="와디즈, 텀블벅, 홈쇼핑 등 단발성 대량출고"
                    path="/fulfillment/event"
                    badges={["B2B", "B2C"]}
                    Icon={<SubmenuSurpriseIcon width={28} height={28} />}
                  />
                </ul>

                <Divide
                  lineStyle="line"
                  thickness={1}
                  type="vertical"
                  height="230px"
                />

                <ul>
                  <Styled.submenuTitle>Trade Management</Styled.submenuTitle>

                  <SubmenuItem
                    label="대금결제(T/T)"
                    desc="은행 방문없이 온라인 무역대금 이체"
                    path="/tt"
                    Icon={<SubmenuGiveMoneyIcon width={28} height={28} />}
                  />
                </ul>
              </Styled.submenuPanel>
            </MenuItem>

            <MenuItem label="기술">
              <Styled.submenuPanel width="273px">
                <ul>
                  <SubmenuItem
                    label="오픈 API"
                    desc="발주, 운송 등 Open API 문서 제공"
                    path="https://developers.ship-da.com/"
                    Icon={<SubmenuCodeIcon width={28} height={28} />}
                  />
                </ul>
              </Styled.submenuPanel>
            </MenuItem>

            <MenuItem label="고객지원">
              <Styled.submenuPanel width="264px">
                <ul>
                  <SubmenuItem
                    label="가이드"
                    desc="이용방법, 용어 및 프로세스, FAQ"
                    path="/support/guide"
                    Icon={<SubmenuBookIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label="공지사항"
                    desc="서비스/정책 주요사항 안내"
                    path="/support/notice"
                    Icon={<SubmenuAnnouncementIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label="동영상"
                    desc="서비스 및 상세프로세스 영상"
                    path="/support/onlinelecture"
                    Icon={<SubmenuPlayerIcon width={28} height={28} />}
                  />
                </ul>
              </Styled.submenuPanel>
            </MenuItem>
          </Styled.menuContainer>
        </nav>
      </Styled.leftContainer>

      <Styled.authContainer>
        {loggedIn ? (
          <User isTransparentBackground={isTransparentBackground} />
        ) : (
          <>
            <TextButton
              label={t("common-new:헤더_회원가입_버튼")}
              theme="gray"
              fontSize={14}
              handleClick={() => {
                Router.push("/register");
              }}
              className="custom-header-register-text-button"
            />

            <TextButton
              label={t("common-new:헤더_로그인_버튼")}
              theme="gray"
              fontSize={14}
              handleClick={() => setIsVisibleLoginModal(true)}
              className="custom-header-register-text-button"
            />
          </>
        )}

        <LinkButton
          label={t("common-new:헤더_도입문의_버튼")}
          theme="primary"
          borderType="filled"
          size="small"
          href={SERVICE_INTRODUCTION_INQUIRY_URL as string}
        />
      </Styled.authContainer>
    </Styled.desktopHeader>
  );
}
