import React from "react";
import Link from "next/link";
import { Trans, useTranslation } from "next-i18next";

import Styled from "./index.styles";

export default function SupportAndTermsMenu() {
  const { t } = useTranslation(["common-new"]);

  return (
    <Styled.supportAndTermsMenuContainer>
      <ul>
        <li className="item">
          <Link href="/">
            <a>{t("common-new:푸터_홈")}</a>
          </Link>
        </li>

        <li className="item">
          <Link href="/support/notice">
            <a>
              <Trans i18nKey="common-new:푸터_고객지원" />
            </a>
          </Link>
        </li>

        <li className="item">
          <Link href="/policy/use">
            <a>
              <Trans i18nKey="common-new:푸터_이용약관" />
            </a>
          </Link>
        </li>

        <li className="item">
          <Link href="/policy/privacy">
            <a>
              <Trans i18nKey="common-new:푸터_개인정보취급방침" />
            </a>
          </Link>
        </li>

        <li className="item">
          <Link href="/recruit">
            <a target="_blank">
              <Trans i18nKey="common-new:푸터_인재_채용" />
            </a>
          </Link>
        </li>
      </ul>
    </Styled.supportAndTermsMenuContainer>
  );
}
