import React from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import Styled from "./index.styles";

export type LogoType = "header" | "footer";

export default function Logo({ type }: { type: LogoType }) {
  const { t } = useTranslation(["common-new"]);

  return (
    <Styled.logoContainer type={type}>
      <Link href="/">
        <a className={"logo"}>
          <h1>{t("common-new:헤더_로고_문구")}</h1>
        </a>
      </Link>
    </Styled.logoContainer>
  );
}
