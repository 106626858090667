import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "next-i18next";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { validateEmail } from "@sellernote/_shared/src/utils/common/validation";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";
import {
  authActions,
  authActionType,
} from "@sellernote/shipda-kr/src/modules/auth";
import { useAppSelector } from "@sellernote/shipda-kr/src/store";

import Styled from "./index.styles";

export default function FindPassword({
  setIsVisibleFindPassword,
}: {
  setIsVisibleFindPassword: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["auth-new", "common"]);

  const { findPasswordResult, isLoading } = useAppSelector((state) => {
    return {
      findPasswordResult: state.auth.findPassword,
      isLoading: state.loading?.[authActionType.FIND_PASSWORD] as boolean,
    };
  });

  const [email, setEmail] = useState("");

  const errorModal = useMemo(getErrorModal, [
    dispatch,
    findPasswordResult?.failureInfo,
    findPasswordResult?.status,
    t,
  ]);
  const successModal = useMemo(getSuccessModal, [
    dispatch,
    findPasswordResult?.status,
    setIsVisibleFindPassword,
    t,
  ]);

  function handleSubmit() {
    dispatch(authActions.findPassword(email));
  }

  function getSuccessModal() {
    if (findPasswordResult?.status === "SUCCESS") {
      const message = <Trans i18nKey="auth-new:API_로그인_비밀번호찾기_성공" />;

      return (
        <Modal
          active={true}
          uiType="content"
          title={t("auth-new:로그인_비밀번호찾기_성공_모달") + ` 🎉`}
          body={message}
          actionPositive={{
            label: t("common:confirm"),
            handleClick: () => {
              dispatch(authActions.initFindPassword());

              setIsVisibleFindPassword(false);
            },
          }}
          className="find-password-success-modal"
        />
      );
    }

    return null;
  }

  function getErrorModal() {
    if (findPasswordResult?.status === "FAILURE") {
      let message = null;

      const { code } = findPasswordResult.failureInfo || {};

      switch (code) {
        case 404: {
          message = <Trans i18nKey="auth-new:API_로그인_비밀번호찾기_실패" />;
          break;
        }
        default: {
          message = <Trans i18nKey="auth-new:API_로그인_비밀번호찾기_에러" />;
          break;
        }
      }

      return (
        <Modal
          active={true}
          uiType="content"
          title={t("auth-new:API_로그인_비밀번호찾기_실패")}
          body={message}
          actionPositive={{
            label: t("common:confirm"),
            handleClick: () => {
              dispatch(authActions.initFindPassword());
            },
          }}
          className="find-password-error-modal"
        />
      );
    }

    return null;
  }

  return (
    <>
      <Styled.container className="find-password-container">
        <Styled.formContainer>
          <div className="find-password-header">
            <Icon
              type="arrowLeft"
              size={2}
              color={COLOR.grayScale_600}
              onClick={() => setIsVisibleFindPassword(false)}
            />
            <h2>{t("auth-new:공통_로그인_비밀번호찾기")}</h2>
          </div>

          <p className="desc">{t("auth-new:로그인_비밀번호찾기_안내")}</p>

          <InputText
            name="email"
            borderType="filled"
            valueType="email"
            label={t("auth-new:공통_로그인_이메일")}
            value={email}
            placeholder={t("auth-new:공통_입력")}
            setValue={(v) => {
              setEmail(v as string);
            }}
            className="input-email"
            errorMessage={
              validateEmail({ val: email, allowEmpty: true, Trans }).message
            }
            isValidated={validateEmail({ val: email, Trans }).result}
            noWhiteSpace
          />

          <Button
            theme="primary"
            size="normal"
            label={t("auth-new:로그인_비밀번호찾기_버튼")}
            handleClick={handleSubmit}
            disabled={!validateEmail({ val: email, Trans }).result}
          />

          <Styled.registerContainer>
            <span className="forgot-email-guide">
              {t("auth-new:로그인_비밀번호찾기_고객센터문의_문구")}
            </span>

            <a
              href={"https://ship-da.channel.io"}
              target="_blank"
              rel="noopener noreferrer"
              className="contact-customer-center-button"
            >
              {t("auth-new:로그인_비밀번호찾기_고객센터_문의_버튼")}
            </a>
          </Styled.registerContainer>
        </Styled.formContainer>

        <Styled.imageContainer>
          <img
            src="/assets/images/login/find-password-modal.png"
            className="find-password-img"
          />

          <img src="/assets/images/layout/logo.webp" className="logo" />

          <span className="shipda-desc">
            <Trans i18nKey="auth-new:공통_로그인_서비스소개" />
          </span>
        </Styled.imageContainer>
      </Styled.container>

      <Loading label={t("auth-new:로그인_로그인중")} active={isLoading} />

      {successModal}
      {errorModal}
    </>
  );
}
