import { useAtom } from "jotai";

import { COMMON_LAYOUT_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/layout";

import PopUpAboutForwardingThcPrice from "./components/PopUpAboutForwardingThcPrice";

/**
 * '쉽다 전체'에 팝업을 띄워야할 때 사용.
 * 일부 페이지에 삽입하는 형식과 다르게 예외적으로 쉽다 모든 페이지에 삽입되어야 하므로, 페이지가 변경될 때마다 팝업이 뜨면 노이즈로 느껴질 수 있기때문에,
 * 전역 상태로 추가 관리하고, 닫기를 누른 사용자는 다음 접속까지는 뜨지 않도록 처리. (TODO: 팝업 여러개에 대해서는 지원 안됨. OPENS_POP_UP_FOR_SHIPDA을 Record형태로 변경하는 등의 수정이 필요함. 참고: https://github.com/sellernote/shipda-front/pull/3883#discussion_r1464313481)
 * - 사용방식: 이 컴포넌트는 팝업이 띄워져야할 위치에 삽입되어 있으므로, 이 컴포넌트 내부에 팝업 컴포넌트를 넣는 식으로 사용
 */
export default function PopUpOnShipda() {
  const [opens, setOpens] = useAtom(
    COMMON_LAYOUT_ATOMS.OPENS_POP_UP_FOR_SHIPDA
  );

  return (
    <div>
      {opens && (
        <PopUpAboutForwardingThcPrice onClose={() => setOpens(false)} />
      )}
    </div>
  );
}
